/* --------------------------------------------
   title-component_sp
----------------------------------------------- */

// h2
.c-primary-head {
  margin-bottom: 32px;
  &__title {
    font-size: 2.6rem;
    text-align: center;
    margin: 0;
  }

  &__lead {
    padding: 0 30px;
    text-align: left;
    margin-top: 32px;
  }

  &__btn {
    &__wrap {
      position: static;
      margin: 18px auto 32px;
      max-width: calc(100% - 30px);
      display: flex;
      justify-content: center;
    }
  }
}

// h3
.c-secondary-head {
  &__title {
    font-size: 1.6rem;
    padding-bottom: 16px;
    margin: 0 0 32px 0;
  }
}
