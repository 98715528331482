// 入会について
.p-entrance {
  max-width: 1200px;
  margin: 0 auto 106px;
  &__ttl {
    margin-bottom: 56px;
    text-align: center;
    &__main {
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 8px;
      letter-spacing: 0.1em;
      display: block;
    }
    &__sub {
      font-size: 1.4rem;
      color:rgba(0,0,0,0.25);
      font-family: 'Nunito', sans-serif;
      display: block;
      letter-spacing: 0.05rem;
    }
  }

  &__trial-ttl {
    width:530px;
    padding: 24px 32px;
    position: relative;
    text-align: center;
    font-size: 2.2rem;
    margin:32px auto 48px;
    letter-spacing: 0.1em;

    &::before,
    &::after {
      content: '';
      width: 25px;
      height: 25px;
      position: absolute;
    }
    &::before {
      border-left: solid 1px $textColor;
      border-top: solid 1px $textColor;
      top: 0;
      left: 0;
    }
    &::after {
      border-right: solid 1px $textColor;
      border-bottom: solid 1px $textColor;
      bottom: 0;
      right: 0;
    }
  }

  &__lead {
    font-size: 1.4rem;
    line-height: 2.4em;
    max-width: 720px;
    margin: auto;
     a{
      display:inline-block;
      padding: 0 8px; 
      color:#849823
     }
  }

  &-info {
    display: flex;
    padding: 48px;
    background:#fff;
    border-radius: 20px;
    flex-wrap: wrap;
    position:relative;
    align-items: center;
    max-width: 1000px;
    margin: 48px auto 32px;
    &__ttl {
      font-size: 1.8rem;
      letter-spacing:0.1em;
      width: 24%;
    }

    &__num {
      font-size: 3rem;
      letter-spacing: 0.1em;
      font-family: 'Nunito', sans-serif;
      font-weight:bold;
    }
    &__box {
      flex:1;
    }
    &__txt {
      line-height: 2;
      margin-top: 8px;
      span {
        display: block;
      }
    }
    &__btn {
      margin:0;
    }
  }


  &__content {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    &__ttl {
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 24px;
      letter-spacing: 0.1em;
    }
    &__txt {
      letter-spacing: 0.05em;
      line-height: 2;
      margin-bottom: 32px;
      span {
        font-size: 1.2rem;
        display: block;
      }

    }
  }
}
