// コース紹介
.p-course {
  max-width: 1200px;
  margin: 0 auto 86px;
  &__box {
    margin: 0 20px;
    display: block;
    padding: 20px 24px 32px;
  }
  &__ttl {
    margin-bottom: 32px;
    text-align: center;
    &__main {
      font-size: 2.2rem;
    }
    &__sub {
      font-size: 1.2rem;
    }
  }
  &-age {
    display: block;
    align-items: center;
    margin-bottom: 24px;
    &__ttl {
      padding: 6px 12px;
      margin-bottom: 16px;
    }
    &__txt {
      span {
        display: block;
        margin-top: 6px;
      }
    }
  }
  &__img {
    width: 100%;
    max-width: 480px;
    margin: 0 auto 32px;
  }

  &-info {
    width: 100%;
    &__box {
      display: block;
    }
    &__ttl {
      border-right: none;
      width: 100%;
      padding: 12px 16px;
    }
    &__txt {
      width: 100%;
      flex:1;
      padding: 12px 16px;

    }
  }

}
.p-voice {
  padding: 32px 0 0;
}
.p-voice__detail {
    margin-right: 10px;
    width:190px;
}