.p-news-d {
  &__detail-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 64px;
  }

  &-ttl {
    font-size: 2.0rem;
  }

  &__content-wrap {
    p:nth-child(1) {
      margin-bottom: 40px;
      font-size: 1.4rem;
    }

    p:nth-child(2),
    p:nth-child(3) {
      margin-bottom: 32px;
    }

    a:nth-child(4) {
      margin-bottom: 48px;
    }

    ul {
      margin-bottom: 48px;

      li {
        display: flex;
        flex-flow: row;
        align-items: baseline;
      }
    }

    ol {
      margin-bottom: 80px;
      li {
        display: flex;
      }
    }

    blockquote {
      margin-bottom: 64px;
    }
  }

  &__img {
    margin-bottom: 48px;
  }

  &__back {
    margin-bottom: 64px;
    &-btn {
      display: flex;
      justify-content: center;

      padding: 24px 0;
      width: 100%;
    }
  }
}
