.l-footer {
  // &__logo {
  //   width: 126px;
  //   height: 48px;
  //   margin: 0 auto 64px;
  // }

  &__backToTop {
    margin: 0 0 40px 0;
  }

  // &-nav {
  //   &__list {
  //     flex-direction: column;
  //     margin: 0 auto 96px;
  //   }

  //   &__item {
  //     margin: 0 0 13px;
  //     &:last-child {
  //       margin: 0;
  //     }
  //   }
  // }

  &__copyright {
    margin: 0 0 40px;
  }
}
