.p-news {
  width: 100%;
  &__list {
    width: calc(100% - 48px);
    margin: auto;
    &-wrap {
      display: flex;
      flex-direction: column;
      margin-top: 0;
    }

    &-cont {
      margin-bottom: 33px;
    }

    &-item {
      padding: 18px 0;

      &::after {
        right: 0;
      }
    }
    &-note {
      padding-right: 32px;
    }

    &-pa {
      margin-bottom: 64px;

      li,
      li.previous a,
      li.next a {
        // width: 40px;
        height: 40px;
        line-height: 40px;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
}
