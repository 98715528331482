
// ヒーロ-
.p-hero {
  position: relative;
  padding: 0 70px;
  margin: 0 auto;
  &__inner {
    &::before {
      position:absolute;
      left:0;
      top:0;
      right:0;
      bottom:0;
      width: 100%;
      height: 100%;
      // background: linear-gradient(to right,rgba(240, 212, 92, 0.1) 0%, rgba(240, 212, 92, 0.05) 100%);
      z-index:9;
      border-radius: 20px;
      content :'';
    }
  }

  // キャッチコピー
  &__catch {
    width:45%;
    max-width: 913px;
    left: 0;
    bottom: -40px;
    position: absolute;
    z-index: 99;
  }

  // リード文
  &__lead {
    position:absolute;
    left: 15%;
    top: 46%;
    margin: auto;
    z-index:99;
    transform: translateY(-50%);
  }

  // ボタン
  &__btn {
    right: 90px;
    bottom: -32px;
    position: absolute;
    z-index: 99;
    display: flex;
    gap:16px;

    &::before {
      display: block;
      width: 60px;
      height: 90px;
      background:url('../img/common/ico-director.svg');
      background-size: contain;
      background-repeat:no-repeat;
      content :'';
      position:absolute;
      bottom:0;
      left: -80px;
    }

    &__item {
      height: 140px;
      width: 140px;
      border-radius: 140px;
      // border-radius: 12px;
      // width: 240px;
      // height: 70px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
      transition: 0.4s all;
      text-align:center;
      span {
        display:block;
      }
      a {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }

      &--red {
        background:#D55252;
      }
      &--green {
        background:#849823;
      }
      &:hover {
        transform: scale(.97);
        // transform: translateY(-4px);
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
      }
    
    }
    &__main-txt {
        font-size: 1.6rem;
        color:#fff;
    }
    &__sub-txt {
      font-size: 1.2rem;
      font-weight: 500;
      color:rgba(255,255,255,0.5);
    }

  }

  // MV
  &__list {
    height: calc(100vh - 160px);
    overflow: hidden;
    border-radius: 20px;
  }
  &__item {
    overflow:hidden;
  
    img {
      object-fit:cover;
      width: 100%;
      display: block;
      height: 100%;
    }

    &.swiper-slide-active img,
    &.swiper-slide-duplicate-active img,
    &.swiper-slide-prev img{
      animation: zoomUp 10s linear 0s 1 normal both;  
    }

    // &.swiper-slide-duplicate-active {
    //   img {
    //     transition: 0.5s all;
    //     transform: scale(1.5);
    //   }
    // }

  }
  &__pager {
    position:absolute;
    right: -40px;
    left: auto !important;
    top:0;
    bottom: 40px !important;
    width: 40px !important;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction:column;
    align-items: center;
    gap:12px;

    span {
      width: 10px;
      height: 10px;
      background:#B0B0B0;
      border-radius: 10px;
      &.swiper-pagination-bullet-active{
        background:$textColor;
      }
  
    }
  }

  &__container {
    max-width: 1080px;
    overflow: visible;
    &--hajl {
      max-width: none;
    }
    // button下部消す用
    &::before {
      content: "";
      width: 32px;
      height: 15px;
      background: #fff;
      position: absolute;
      bottom: -15px;
      right: 32px;
      z-index: 11;
    }

    &.is-close {
      &::before {
        content: none;
      }

      // close時のactiveslideのborder
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #f2f2e9;
        z-index: 2;
      }
    }
  }
  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    z-index: 1;
    cursor: pointer;
    background-size: 8px 12px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 16px;
    height: 24px;

    &:hover {
      opacity: 0.6;
    }
  }
  &__prev {
    background-image: url("../img/common/ico-arrow-black-left.svg");
    left: 20px;
  }
  &__next {
    background-image: url("../img/common/ico-arrow-black-right.svg");
    right: 20px;
  }
}

// おたより
.p-top-news {
  display :flex;
  margin: 160px auto 200px;
  &__head {
    width: 30%;
    max-width: 268px;
    &__ttl {
      font-size: 1.4rem;
      font-weight: 400;

      span {
        font-family: 'Nunito', sans-serif;
        font-size: 3rem;
        display: block;
        margin-bottom:8px;
        letter-spacing:0.1em;
      }
    }
  }

  &__box {
    flex:1;
  }
  &__item {
    list-style: none;
    &:last-of-type {
      a {
        border-bottom: none;
      }
    }
    a {
      display :flex;
      align-items: center;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $darkBaseColor ;
    }
    &__date {
      letter-spacing:0.02em;
      font-size: 1.5rem;
      font-family: 'Nunito', sans-serif;

    }
    &__tag {
      background:#fff;
      border-radius: 16px;
      margin: 0 14px;
      width: 133px;
      text-align:center;
      padding: 4px 0;
      &--green {
        color:#849823;
      }
      &--red {
        color:#D55252;
      }
    }
    &__txt {
      font-size:1.4rem;
      flex:1;
    }
  }

  &__btn {
    border: 1px solid #849823;
    border-radius: 24px;
    max-width: 128px;
    text-align:center;
    margin:0 auto;
    height: 40px;
    line-height: 40px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      color:#849823;
    }
  }
}

// はまみ音楽教室とは
.p-top-about {
  position: relative;
  margin: 0 auto 160px;
  padding: 100px 0 120px;
  background:#fff;

  // &::before {
  //   background:#F9F5F3;
  //   width: 100%;
  //   top:0;
  //   bottom:0;
  //   position:absolute;
  //   z-index:-2;
  //   content: '';
  // }
  &__bac {
    width: 100%;
    left:0;
    right: 0;
    position: absolute;
    z-index:-2;

    img {
      height: 100%;
      width: 100%;
    }

    &--top {
      top: -48px;
      height: 50px;
      background:url("../img/top/bac-curve-top2-white.svg");
      background-repeat:no-repeat;
      background-size: cover;
    }
    &--bottom {
      bottom:-48px;
      height: 50px;
      background:url("../img/top/bac-curve-bottom2-white.svg");
      background-repeat:no-repeat;
      background-size: cover;
    }
  }
  &__box {
    position:relative;
    margin-bottom: 82px;
    z-index:1;
    &__img {
      max-width: 1000px;
      position: absolute;
      top:-90px;
      left:0;
      right:0;
      z-index:-1;
      margin: auto;
      opacity:0;
      width:60%;
      transition: all 1.2s 0.5s ease-out;
      &.is-show {
        opacity:1;
        width:96%;
      }
    }
    &__piano-img {
      max-width: 1000px;
      position: absolute;
      top:-80px;
      left:0;
      right:0;
      z-index:-1;
      margin: auto;
      opacity:0;
      width:90%;
      transition: all 0.3s 1s ease-out;
      &.is-show {
        top:-90px;
        opacity:1;
        width:96%;
      }
    }

  }
  &__ttl {
    margin-bottom: 40px;
    text-align: center;
    &__main {
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 8px;
      letter-spacing: 0.1em;
      display: block;
    }
    &__sub {
      font-size: 1.4rem;
      // font-weight: bold;
      color:rgba(0,0,0,0.25);
      font-family: 'Nunito', sans-serif;
      display: block;
      letter-spacing: 0.05rem;
    }
  }
  &__txt {
    margin-bottom: 40px;
    text-align:center;
    font-size: 1.4rem;
    line-height: 2;
    letter-spacing: 0.1rem;
  }
  &__img {
    width: 100%;
    // max-width: 1200px;
    margin: 0 auto;
    position:relative;
    z-index:1;
    overflow-x:hidden;
    // height: 420px;
    // width:auto;
    img {
      max-height: 480px;
      width:auto;
  
    }

    &-wrap {
      position:relative;
      &::before,
      &::after {
        display: block;
        width: 40px;
        height: 92px;
        position:absolute;
        content :'';
        background-repeat:no-repeat !important;
        z-index:111;
      }
      &::before {
        background:url('../img/common/ico-tree-yellow.svg');
        background-size: contain;
        left: 30px;
        bottom: -30px;
      }
      &::after {
        background:url('../img/common/ico-tree-green.svg');
        background-size: contain;
        right: 30px;
        bottom: -120px;
      }
  
    }


  }
}

// インスタグラム
.p-top-instagram {
  position: relative;
  margin: 0 auto 200px;
  display: flex;
  width: 92%;
  max-width: 1400px;
  display: flex;
  // .swiper-wrapper {
  //   position: relative;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 1;
  //   display: flex;
  //   transition-property: transform;
  //   box-sizing: content-box;
  // }

  &__box {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 30%;
    margin-right:48px;
    flex-direction: column;

  }
  &__ttl {
    font-family: 'Nunito', sans-serif;
    margin-bottom: 24px;
    font-size: 3.6rem;
    letter-spacing: 0.1em;
    text-align: center;
  }
  &__txt {
    margin-bottom: 40px;
    text-align:center;
    font-size: 1.4rem;
    line-height: 2;
    letter-spacing: 0.1rem;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    overflow:hidden;
    width: 100%;
    // border-radius: 16px;
  }
  &__item {
    width: 25%;
    // width: 250px;
    height: 0;
    padding-top: 25%;
    // height: 250px;
    border: 1px solid #fff;
    box-sizing:border-box;
    position:relative;
    a {
      display: block;
      width: 100%;
      height: 100%;
      position:absolute;
      top:0;
    }
    img {
      object-fit: cover;
      width:100%;
      height: 100%;
    }
  }
  &__btn {
    margin-bottom: 48px;
  }
}

// 動画
.p-top-movie {
  position: relative;
  background:#849823;
  width: calc(100% - 48px);
  max-width: 1200px;
  border-radius: 20px;
  margin:0 auto 200px;
  display: flex;
  color:#fff;
  justify-content: flex-end;

  &.is-show {
    .p-top-movie__box::before {
      bottom:-15px;
      opacity:1;
    }
  }

  &__ttl {
    text-align: center;
    margin-bottom: 24px;
    &__main {
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 8px;
      letter-spacing: 0.1em;
      display: block;
    }
    &__sub {
      font-size: 1.4rem;
      // font-weight: bold;
      color:rgba(255,255,255,0.25);
      font-family: 'Nunito', sans-serif;
      display: block;
      letter-spacing: 0.05rem;
    }
  }
  &__box {
    padding:72px 72px 72px 0;
    width:38%;
    overflow: hidden;

    &::before {
      display: block;
      width: 60px;
      height: 90px;
      background:url('../img/common/ico-director.svg');
      background-size: contain;
      background-repeat:no-repeat;
      content :'';
      position:absolute;
      bottom:-90px;
      right: 40px;
      transition: all 0.3s 0.3s ease-out;
      z-index:-1;
      bottom:-35px;
      opacity:0;
    }
  
}
  &__txt {
    margin-bottom: 40px;
    text-align:center;
    font-size: 1.4rem;
    line-height: 2;
    letter-spacing: 0.1rem;
  }
  &__item {
    position: absolute;
    margin: 0 48px;
    width: 50%;
    // height: 0;
    overflow: hidden;
    border-radius: 12px;
    border: 3px solid #fff;
    border-radius: 12px;
       a {
      display:block;
      width: 100%;
      height: 100%;
    }
    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      
    }
    &:first-of-type {
      left: 0px;
      width: 36%;
      // padding-bottom: 25%;
      top:-40px;
      // height: 260px;
    }
    &:last-of-type {
      bottom: -20px;
      // padding-bottom: 18%;
      left: 32%;
      width: 25%;
      // height: 200px;
    }

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

// マップ
#p-map {
  // &::before {
  //   display: block;
  //   content: '';
  //   background:url('../img/common/bac-curve-bottom-white.svg');
  //   background-size:cover;
  //   background-position:bottom center;
  //   width: 100%;
  //   height: 50px;
  //   top: -1px;
  //   position:absolute;
  //   left: 0;
  //   right: 0;
  //   z-index:1;
  // }
  position:relative;
  height: 400px;
}

// インスタ
.p-instagram {
  position:fixed;
  right: 32px;
  bottom:32px;
  width: 23px;
  height: 118px;
  background:url('../img/common/btn-instagram.svg');
  background-size: contain;
  // background-position:
  a {
    display:block;
    width: 100%;
    height: 100%;
  }
}


.p-top-about__wave-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  margin-top: -120px;
  // transform: rotate(180deg);
  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 120px;
  }
  .shape-fill {
    fill: #FFFFFF;
  }
}


.p-top-about__wave-bottom {
  position: absolute;
  bottom: -120px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 120px;
  }
  .shape-fill {
    fill: #FFFFFF;
  }
}
