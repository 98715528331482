// レッスン時間
.p-lesson {
  max-width: 1200px;
  margin: 0 auto 86px;
  &__box {
    margin: 0 24px;
    display: block;
    padding: 24px;
  }
  &__ttl {
    margin: 0 24px 32px;
    text-align: center;
    &__main {
      font-size: 2.2rem;
    }
    &__sub {
      font-size: 1.2rem;
    }
  }
  &-table {
    width: 90%;
    margin: 0 auto;
    tr {
      border-bottom: 1px solid $darkBaseColor;
      &:last-of-type {
        border-bottom:none;
      }
    }
    th {
      width: 12%;
      border-right: 1px solid $darkBaseColor;
      padding: 16px;
      text-align:center;
    }
    td {
      width: 44%;
      border-right: 1px solid $darkBaseColor;
      padding: 16px 16px;
      &:last-of-type {
        border-right: none;
      }
    }
  }

}

.fc .fc-toolbar.fc-header-toolbar  {
  width: 94%;
  margin : 0 auto 1.5em;

}
.fc .fc-toolbar-title {
  font-size: 2rem;
  text-align: center;
}

.fc .fc-col-header-cell-cushion {
  font-size: 1rem;
  letter-spacing:-0.07em;
}
.fc .fc-timegrid-slot-label-cushion {
  font-size: 1rem;
  letter-spacing:-0.07em;
}
.fc-theme-standard .fc-scrollgrid {
  border-left: none;
  border-right:none;
}
.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
  padding: 0 2px;
}