/* --------------------------------------------
  page-head
----------------------------------------------- */

.l-page-head {
  height: 200px;
  // width: calc(100% - 30px);
  width:100%;
  border-radius: 0;
  margin-bottom: 80px;

  &__inner {
  }
  &__ttl {
    // color:#fff;
    font-size: 2rem;
    // font-weight: 500;
    // letter-spacing: 0.1em;
    // text-align:center;
  }
  &__sub-ttl {
    font-size: 1.6rem;
    margin-top: 8px;
    letter-spacing: 0.6em;
  }

  //ナビ
  &__nav {
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 60px;
    padding: 20px 24px;
    width: 100%;
    margin: 0 auto 0;
    border-radius: 20px 20px 0 0;
    gap:12px;
    background:#849823;
    z-index:9;
    &--center {
      justify-content:center;
    }

    &__item {
      margin: 0 0;
      a {
        font-weight:bold;
        color:#fff;
      }
    }
  }

  &__sub-txt {
    display:none;
    bottom: -202px;
    width: 82%;
  }
}