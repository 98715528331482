.c-baseTab {
  padding: 0 15px;
  height: 71px;
  &__item {
    margin: 0 4px;
  }

  &__btn {
    min-height: 47px;
    height: 56px;
    font-size: 1.4rem;
    text-align: center;
    &.is-active {
      min-height: 60px;
    }

    &.is-unread {
      span {
        &::after {
          right: -8px;
        }
      }
    }
  }

  &--active {
    height: 62px;
  }
}
