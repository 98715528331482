.l-header {
  width: 100%;

  &__inner {
    height: 68px;
    padding: 0 24px;
  }
  &__logo {
    width: 160px;
    height: 34px;
  }

  &-nav {
    width:100%;
    height: 0;
    top:0;
    left:0;
    position:fixed;
    background:#849823;
    transition: .3s;
    display:block;
    opacity:0;
    &.is-active {
      height: 100%;
      opacity:1;
      .l-header-nav__list {
        height: 100vh;
        padding: 72px;
      }
    }
    &__wrap {
      align-items:center;
      display: flex;
      height: 100%;
      position: absolute;
      width: 100%;
    }
    &__list {
      left: 0;
      top:0;
      padding: 0 72px;
      position: absolute;
      width: 100%;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #efefef;
      height: 0;
      overflow:hidden;

      a {
        color:#fff;
        letter-spacing: 0.05em;
      }
    }
    &__item {
      margin: 12px 0 12px 0;
      opacity: 0;

      &.is-current{

        &::before {
          width: 0;
          display:none;
        }
      }

      &.is-current a {
        color:#fff;
      }
      a {
        transition: .5s;
        font-size: 1.6rem;
        min-width:24px;
        span{
          display:block;
          text-align:center;
          color:rgba(255,255,255,0.5);
          margin-top:8px;
          letter-spacing:0.1em;
          font-size:1.2rem;
        }
  
        &:hover {
          color:#fff;
          opacity:1;

          &::before {
            width: 0px;
          }
        }
      }

    }
  }

  &__btn {
    padding: 0;
    margin: 0;
    position: fixed;
    top: 12px;
    right: 16px;
    width: 36px;
    height: 46px;
    z-index: 10;

    &__line {
      display: block;
      position: relative; 
      width: 100%; 
      height: 1px;
      background-color: #222;
      transition: .2s;
    }
    
    &__line::before,
    &__line::after {
      left:0;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #222;
      transition: .5s;
    }
    &__line::before {
      transform: translateY(-10px);
    }
    &__line::after {
      transform: translateY(10px);
    }

    &.is-active {
      .l-header__btn__line {
        background-color: transparent;
        &::before ,
        &::after {
          content: "";
          background-color: #fff;
          transition: .2s;
        }
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }
    // &.is-active::before {
    //   transform: rotate(45deg);
    // }
    // &.is-active::after {
    //   transform: rotate(-45deg);
    // }

    
  }

}

