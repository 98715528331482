// レッスン時間
.p-lesson {
  max-width: 1000px;
  margin: 0 auto 120px;

  &--free-time{
    margin: -90px auto 120px;
    padding-top: 90px;
  }
  &__ttl {
    margin-bottom: 56px;
    text-align: center;
    &__main {
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 8px;
      letter-spacing: 0.1em;
      display: block;
    }
    &__sub {
      font-size: 1.4rem;
      color:rgba(0,0,0,0.25);
      font-family: 'Nunito', sans-serif;
      display: block;
      letter-spacing: 0.05rem;
    }
  }


  &-table {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    tr {
      border-bottom: 1px solid $darkBaseColor;
      &:last-of-type {
        border-bottom:none;
      }
    }
    th {
      width: 20%;
      border-right: 1px solid $darkBaseColor;
      padding: 16px;
      text-align:center;
    }
    td {
      width: 40%;
      border-right: 1px solid $darkBaseColor;
      padding: 16px 32px;
      &:last-of-type {
        border-right: none;
      }
    }
  }

}

.fc-toolbar-title {
  font-size: 2.6rem;
  letter-spacing:0.1em;
  // font-family: 'Nunito' sans-serif;

}
.fc-toolbar-title::after{
  display: inline-block;
  content: 'の空き時間';
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 40px;
}
.fc-theme-standard td, .fc-theme-standard th {
  border: 1px solid $darkBaseColor ;
}

.fc-v-event {
background:$themeColor !important;
border:1px solid $themeColor !important;
}
.fc .fc-button-primary {
  background:$baseColor !important;
  border:1px solid $textColor !important;

}
.fc-icon-chevron-right:before,
.fc-icon-chevron-left:before {
  color:$textColor;
}
.fc .fc-button-primary:disabled,
.fc .fc-button:not(:disabled), .fc a[data-navlink], .fc-event.fc-event-draggable, .fc-event[href] {
  color:$textColor;
}
.fc .fc-button {
  border-radius: 20px;
  padding: .3em .4em;
}


