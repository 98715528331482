/* --------------------------------------------
   title-component_pc
----------------------------------------------- */

// h2
.c-primary-head {
  position: relative;
  margin-bottom: 56px;

  &__title {
    font-size: 3.2rem;
    // line-height: 1;
    font-weight: bold;
  }

  &__lead {
    margin-top: 32px;
    font-weight: 500;
  }

  &__btn {
    font-size: 1.4rem;
    line-height: 1.4285714286;
    padding: 10px 26px;
    border-radius: 30px;
    font-weight: $bold;
    text-align: center;
    max-width: 100%;

    &__wrap {
      position: absolute;
      right: 0;
      top: 0;
    }

    &--border {
      color: #2b2b2b;
      background: #fff !important;
      box-shadow: 0 0 0 2px #2b2b2b inset;
    }

    &--fill {
      color: #fff;
    }
  }
}

// h3
.c-secondary-head {
  position: relative;

  &__title {
    font-size: 2.4rem;
    line-height: 1;
    font-weight: bold;
    padding-bottom: 30px;
    margin: 0 0 32px 0;
    border-bottom: 1px solid #262626;
  }
}
