/* --------------------------------------------
   ボーダーなしボタン | button without border
----------------------------------------------- */
.c-btn {
  cursor: pointer;
  background:#849823;
  width: 234px;
  height: 52px;
  border-radius: 33px;
  margin:0 auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transition: 0.4s all;
  // &:hover {
  //   transform: scale(.95);
  // }


  a {
    color:#fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
  
    &::after {
      display: block;
      width: 5px;
      height: 9px;
      background:url('../img/common/ico-arrow.svg');
      content:'';
      right: 16px;
      top:0;
      bottom:0;
      margin: auto;
      position:absolute;
    }
  }

  &:hover {
    transform: scale(.98);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
  }

  &--border-white {
    border:1px solid #fff;
    box-shadow: none;
    &:hover {
      transform:none;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
    }
  }
}


