// 入会について
.p-entrance {
  max-width: 1200px;
  margin: 0 auto 86px;
  &__box {
    margin: 0 24px;
    display: block;
    padding: 24px;
  }
  &__ttl {
    margin: 0 24px 32px;
    text-align: center;
    &__main {
      font-size: 2.2rem;
    }
    &__sub {
      font-size: 1.2rem;
    }
  }

  &__trial-ttl {
    width:90%;
    max-width: 300px;
    padding: 24px 32px;
    font-size: 2rem;
    margin:32px auto 48px;

    &::before,
    &::after {
      content: '';
      width: 25px;
      height: 25px;
      position: absolute;
    }
    &::before {
      border-left: solid 1px $textColor;
      border-top: solid 1px $textColor;
      top: 0;
      left: 0;
    }
    &::after {
      border-right: solid 1px $textColor;
      border-bottom: solid 1px $textColor;
      bottom: 0;
      right: 0;
    }
  }
  &__lead {
    max-width: 100%;
    margin: auto 24px 48px;
     a{
      display:inline-block;
      padding: 0 8px; 
      color:#849823
     }
  }


  &-info {
    margin: auto 24px 32px;
    width: auto;
    padding: 24px;
    &__box {
      display: block;

    }
    &__ttl {
      width: 100%;
      padding: 12px 0 24px;
      text-align:center;
    }
    &__num {
      text-align:center;
    }
    &__txt {
      width: 100%;
      flex:1;
      padding: 12px 0;
    }
    &__btn {
      margin:auto;
    }
  }

}
