// --------------------------------------------------------------------------------
//    Mixin items
// --------------------------------------------------------------------------------
@use "sass:math";

// cleafix
@mixin clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

// font（font-size, line-height, color, letterspacing, font-weight）
@mixin font($fontsize, $lineheight, $color, $letterspacing: normal, $fontweight: normal) {
  font-size: math.div($fontsize, 10) + rem;

  @if $lineheight == normal {
    line-height: normal;
  } @else {
    line-height: math.div($lineheight, $fontsize);
  }
  color: $color;
  @if $letterspacing != 0 {
    letter-spacing: ($letterspacing / 1000) + em;
  }
  font-weight: $fontweight;
}

// backgroundImage（imagePath）
@mixin backgroundImage($path) {
  background-image: url($path);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  @content;
}

// 縦真ん中寄せ
@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// absolute horizontal and vertival center
@mixin abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// 左側icon
@mixin beforeImage($width, $height, $url) {
  &:before {
    content: "";
    display: inline-block;
    width: $width + px;
    height: $height + px;
    background: url($url) center center no-repeat;
    background-size: contain;
    vertical-align: middle;
  }
}

// 左側icon margin-rightあり
@mixin beforeImageMarginRight($width, $height, $url) {
  &:before {
    content: "";
    display: inline-block;
    width: $width + px;
    height: $height + px;
    margin-right: 8px;
    background: url($url) center center no-repeat;
    background-size: contain;
    vertical-align: middle;
  }
}

// 右側icon
@mixin afterImage($width, $height, $url) {
  &:after {
    content: "";
    display: inline-block;
    width: $width + px;
    height: $height + px;
    margin-left: auto;
    background: url($url) center center no-repeat;
    background-size: contain;
    vertical-align: middle;
  }
}
