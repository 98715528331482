/* --------------------------------------------
   keyframes
----------------------------------------------- */
@keyframes zoomUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.15);
  }
}

// fadein
@keyframes fadein {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// fadeout
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@keyframes slidein {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    // transition: all 0.8s;
    z-index: 3;
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    }
}
