/* --------------------------------------------
  layout
----------------------------------------------- */

.l-content {
  margin-top: 90px;
  position:relative;

  &--jp {
    margin-top: 108px;
  }

  &__inner {
    max-width: 1000px;
    width: 96%;
    margin:0 auto;
  }

  .l-main {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;

    &__inner {
      margin: 32px auto 100px;
      width: calc(100% - 64px);
      max-width: 1080px;

      &--stretch {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.js-accordion-content {
  display: none;
}