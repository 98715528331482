.l-header {
  width: 100%;
  // border-bottom: 0.5px solid #d6d6d6;
  position: fixed;
  // background: #fff;
  z-index: 110;
  
  
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    padding: 0 48px;
  }

  &__logo {
    width: 211px;
    height: 48px;
    animation-duration: 1s;
    animation-name: slidein;
  
  }

  &-nav {
    padding: 0 0 0 0px;
    width: 100%;
    height:100%;
    animation-duration: 1.5s;
    // animation-delay: 0.5s;
    animation-name: slidein;
  
    &__list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
    }

    &__item {
      margin-left: 32px;
      position:relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a {
        display: block;
        font-weight: 600;
        letter-spacing:0.05em;

        span{
          display:none;
        }

        &::before {
          position:absolute;
          display:block;
          width: 0px;
          height:2px;
          background:#849823;
          bottom:24px;
          left:0;
          right:0;
          content: '';
        }

        &:hover {
          color:#849823;
          opacity:1;

          &::before {
            width: 100%;
            transition: width 0.5s ease-out 0s; 
          }
        }
      }

      &.is-current {
        a {
          color:#849823;
        }
        &::before {
          position:absolute;
          display:block;
          width: 100%;
          height:2px;
          background:#849823;
          bottom:24px;
          left:0;
          right:0;
          content: '';
        }
      }
    }

  }
}