.p-news-cat {
  width: calc(100% - 48px);
  margin:auto;
  &__ttl {
    position: relative;
  }

  &__list-item {
    // background-color: #EDF2FC;
    a {
      // color: #3761B1;
    }
  }

  &__yearly {
    margin-bottom: 120px;
  }

  &__minus-txt, &__minus-year  {
    display: inline-block;
    position: absolute;
    content: "";
    right: 25px;

    min-width: 24px;
    min-height: 24px;

    flex: 0 1 32px;
    border-radius: 50%;
    // background-image: url(/assets/img/common/ico-minus.svg);
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: inherit;
    // background-color: #fff;
    // transform: rotate(3.142rad);

    cursor: pointer;
  }

  &__plus-txt, &__plus-year {
    display: none;
    position: absolute;
    content: "";
    right: 25px;

    min-width: 24px;
    min-height: 24px;

    flex: 0 1 32px;
    border-radius: 50%;
    // background-image: url(/assets/img/common/ico-plus.svg);
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: inherit;
    // background-color: #fff;
    // transform: rotate(3.142rad);

    cursor: pointer;
  }
}
