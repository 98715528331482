// 5つの想い
.p-point {
  max-width: 1200px;
  margin: auto;
  &__ttl {
    margin-bottom: 56px;
    text-align: center;
    &__main {
      font-size: 2.2rem;
      // font-weight: 600;
      // margin-bottom: 8px;
      // letter-spacing: 0.1rem;
      // display: block;
    }
    &__sub {
      font-size: 1.2rem;
      // font-weight: bold;
      // color:rgba(0,0,0,0.25);
      // font-family: 'Nunito', sans-serif;
      // display: block;
      // letter-spacing: 0.05rem;
    }
  }

  &__list {

  }

  &__item {
    display: flex;
    margin-bottom:72px;
    flex-direction: column;
    &:nth-of-type(odd){
      .p-point__img {
        order: 1;
      }
      .p-point__content {
        order: 2;
        margin-left: auto;
      }
    }
    &:nth-of-type(even){
      .p-point__img {
        order: 1;
      }
      .p-point__content {
        order: 2;
        margin-right: auto;
      }
    }

    &:nth-of-type(1){
      .p-point__content::before {
        width: 42px;
      }
    }
    &:nth-of-type(2){
      .p-point__content::before {
        width: 48px;
      }
    }
    &:nth-of-type(3){
      .p-point__content::before {
        width: 48px;
      }
    }
    &:nth-of-type(4){
      .p-point__content::before {
        width: 50px;
      }
    }
    &:nth-of-type(5){
      .p-point__content::before {
        width: 49px;
      }
    }

  }
  &__img {
    width: calc(100% - 48px);
    max-width: 480px;
    margin: 0 auto 32px;
  }
  &__content {
    width: calc(100% - 48px);
    margin: auto;
    &::before {
      height: 38px;
      margin-bottom: 12px;
    }

    &__ttl {
      font-size: 2rem;
      margin-bottom: 16px;
    }
    &__txt {
      letter-spacing: 0.05em;
      line-height: 2;
    }
  }
}

// 講師紹介
.p-coach {
  margin: 160px auto 60px;
  padding-bottom: 40px;
  &::before {
    top: -80px;
    height: calc(100% + 130px);
  }
  &__bac {
    width: 100%;
    left:0;
    right: 0;
    position: absolute;
    z-index:-2;
    img {
      height: 100%;
      width: 100%;
    }

    &--top {
      height: 40px;
      top:-120px;
      img {
        object-fit: cover;
        object-position: top center;
      }
    }
    &--bottom {
      height: 40px;
      bottom:-80px;
    }
  }
  &__box {
    position:relative;
    margin-bottom: 70px;
    &__img {
      width:90%;
      position: absolute;
      top:-70px;
      left:-24px;
      right:0;
      opacity:0;
      z-index:-1;
      margin: auto;
      transition: all 0.6s 0.3s ease-out;

      &.is-show {
        opacity:1;
        width:calc(100% + 24px);
      }

    }
  }
  &__ttl {
    margin-bottom: 40px;
    // text-align: center;
    &__main {
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 8px;
      letter-spacing: 0.1rem;
      display: block;
    }
    &__sub {
      font-size: 1.4rem;
      // font-weight: bold;
      color:rgba(0,0,0,0.25);
      font-family: 'Nunito', sans-serif;
      display: block;
      letter-spacing: 0.05rem;
    }
  }

  &__content {
    display: block;
    margin: auto;
    width: calc(100% - 48px);
    padding: 24px;
  }
  &__img {
    width: 50%;
    margin: 0 auto 32px;
  }

  &__name {
    color:#849823;
    font-weight: 600;
    font-size: 2.2rem;
    margin-bottom: 32px;
  }
  &__list {
    margin-bottom: 20px;
  }
  &__item {
    margin-bottom: 8px;
  }
}



// アクセス
.p-access {
  margin: auto;
  display: block;
  padding: 32px 24px;
  margin-bottom: -24px;
  position:relative;
  z-index:1;

  &__head {
    text-align:center;
    border-right: none;
    padding-right: 0;
    margin-right: 0;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
  }
  &__main-ttl {
    font-size: 2rem;
    margin-bottom: 2px;
  }
  &__sub-ttl {
    font-size: 1rem;
  }
  &__box{
    p {
      margin-bottom: 4px;
    }
  }
  &__link {
    background:#849823;
    margin-top: 10px;
    border-radius: 24px;
    margin: 16px auto 0;
    padding: 8px 14px 8px 28px;
    display: inline-block;
    float:none;

    &::before {
      background:url('../img/common/ico-pin.svg');
      width: 9px;
      height: 14px;
      content: '';
      display:block;
      position:absolute;
      left:14px;
      top:0;
      bottom:0;
      margin:auto;
    }    
  }
}