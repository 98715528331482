.p-news {
  &__list-wrap {
    display: flex;
    gap: 4%;
    max-width: 1200px;
    margin: 105px auto 0;
  }
  &__list {
    flex:1;
  }

  &__list {
    &-cont {
      margin-bottom: 56px;
    }

    &-item {
      position: relative;
      padding: 15px 24px;
      border-bottom: 1px solid $darkBaseColor;
      color: $textColor;

      &::after {
        position: absolute;
        display: block;
        content: "";

        width: 24px;
        height: 24px;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        flex: 0 1 10px;
        background: url(../img/common/ico-arrow-blue.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &-date {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      font-family: 'Nunito', sans-serif;
    }

    &-txt {
      margin-right: 15px;

      color: $themeColor;
      font-size: 1.4rem;
      // font-weight: $bold;
    }

    &-label {
      border: 1px solid #e8e8e8;
      border-radius: 3px;
      padding: 5px 20px;
      background-color: #f4f7fc;
      border-radius: 20px;
      font-size: 1.2rem;
    }

    &-note {
      padding-right: 16px;

      // font-family: $font_sans;
      font-size: 1.4rem;
      // font-weight: $normal;
    }

    &-pa {
      display: flex;
      margin-bottom: 120px;
      justify-content: center;

      li {
        position: relative;
        // width: 48px;
        height: 48px;
        line-height: 48px;
        margin: 0 15px 0 0;

        // font-family: $font_sans;
        font-size: 14px;
        // font-weight: $bold;
        text-align: center;

        &:nth-child(7) {
          width: 16px;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          background-color: #edf2fc;
          color: #3761b1;
        }
      }

      li.active {
        border-bottom: 2px solid #3761b1;
        background-color: #fff;
        color: #38343c;
      }

      li.previous a {
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        color:$themeColor;
        height: 48px;
        border-radius:24px;
        padding: 0 24px;
      }

      li.next a {
        background: url(../img/common/ico-arrow-blue.svg);
        padding: 0 24px;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        height: 48px;
        border-radius:24px;
        color:$themeColor;
      }

      li.truncate {
        span {
          position: absolute;
          bottom: 12px;
          left: 50%;
          transform: translateX(-50%);
          line-height: 0;
        }
      }
    }
  }
}


