.p-hero {
  padding: 0 0;

  &__lead {
    height: 62vh;
    position:absolute;
    right: 0;
    top: 43%;
    img {
      object-fit: contain;
      max-height: 100%;
    }
  }

  // ボタン
  &__btn {
    right: 0;
    bottom: -32px;
    gap:0;
    width: 100%;
    justify-content: center;
    &__item {
      width: 40%;
      max-width: 220px;
      height: 58px;
      margin-right: 8px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    &__main-txt {
      font-size: 1.4rem;
      color:#fff;
      line-height: 1.45;
      font-weight: 700;
      span {
        font-size: 1rem;
      }
    }
    &__sub-txt {
      display:none !important;
      font-size: 1rem;
      color:rgba(255,255,255,0.5);
    }
  }

  &__pager {
    display:none;
    right: -20px;
    bottom: 40px !important;
    width: 20px !important;
    gap:12px;

    span {
      width: 6px;
      height: 6px;
      background:#CBCBCB;
      border-radius: 6px;
      &.swiper-pagination-bullet-active{
        background:$textColor;
      }
    }
  }

  &__catch {
    width:70%;
    max-width: 713px;
    bottom: 40px;
  }

  &__list {
    height: calc(100vh - 160px);
    border-radius:0;
  }
}

// おたより
.p-top-news {
  margin: 96px auto 140px;
  &__head {
    width: 100%;
    max-width: 100%;
    margin-bottom: 32px;
    &__ttl {
      span {
        font-family: 'Nunito', sans-serif;
        font-size: 2.4rem;
      }
    }
  }
  &__item {
    // &:last-of-type {
    //   a {
    //     border-bottom: none;
    //   }
    // }
    a {
      margin-bottom: 16px;
      padding-bottom: 16px;
      // border-bottom: 1px solid #E0E0E0;
      flex-wrap:wrap;
    }
    &__date {
      font-size: 1.3rem;

    }
    &__tag {
      font-size: 1.2rem;
      margin: 0 12px;
      width: auto;
      padding: 2px 16px;
    }
    &__txt {
      // font-size:1.4rem;
      flex:auto;
      width: 100%;
      margin-top:8px;
    }
  }

  &__btn {
    border: 1px solid #849823;
    border-radius: 24px;
    max-width: 128px;
    text-align:center;
    margin:0 auto;
    height: 40px;
    line-height: 40px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      color:#849823;
    }
  }
}

// はまみ音楽教室とは
.p-top-about {
  margin: 0 auto 120px;
  padding: 100px 0 120px !important;

  &__bac {
    &--top {
      height: 30px;
      top:-30px;
      img {
        object-fit: cover;
        object-position: top center;
      }
    }
    &--bottom {
      height: 40px;
      bottom:-80px;
    }
  }
  &__box {
    margin-bottom: 56px;
    &__img {
      width:90%;
      top:-70px;
      left:-20px;
      transition: all 0.6s 0.3s ease-out;

      &.is-show {
        opacity:1;
        width:calc(100% + 20px);
      }
    }
    &__piano-img {
      width:calc(100% + 20px);
      top:-65px;
      left:-20px;
      transition: all 0.4s 0.8s ease-out;

      &.is-show {
        top:-70px;
        opacity:1;
        width:calc(100% + 20px);
      }
    }

  }
  &__ttl {
    margin-bottom: 40px;
    &__main {
      font-size: 2.4rem;
      margin-bottom: 8px;
    }
    &__sub {
      font-size: 1.2rem;
    }
  }
  &__txt {
    margin: 0 24px 32px;
    text-align:left;
  }
  &__img {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    img {
      height: 300px;
    }

    &::before,
    &::after {
      width: 24px;
      height: 72px;
    }
    &::before {
      left: 24px;
      bottom: -40px;
    }
    &::after {
      right: 24px;
      bottom: -90px;
    }

  }
}

// インスタグラム
.p-top-instagram {
  position: relative;
  margin: 0 auto 80px;
  display: block;
  &__ttl {
    margin-bottom: 24px;
    font-size: 3rem;
  }
  &__txt {
    margin-bottom: 32px;
    text-align:left;
    font-size: 1.4rem;
  }
  &__box {
    width: 100%;
    display: block;
  }
  &__list {
    // border-radius: 12px;
    max-width: 400px;
    margin: 32px auto 0;

  }
  &__item {
    width: 50%;
    height: 0;
    padding-top: 50%;
  }
  &__btn {
    margin-bottom: 48px;
  }
}


// 動画
.p-top-movie {
  width: 100%;
  border-radius: 0;
  margin:0 auto 60px;
  display: block;
  padding-bottom: 80px;
  &__ttl {
    margin-bottom: 24px;
    &__main {
      font-size: 2.4rem;
    }
    &__sub {
      font-size: 1.2rem;
      letter-spacing: 0.05rem;
    }
  }

  &.is-show {
    .p-top-movie__box::before {
      bottom:auto;
      top:-20px;
    }
  }

  &__box {
    padding:48px 24px 48px;
    width:100%;

    &::before {
      bottom:auto;
      top:0;
    }
  }
  &__txt {
    margin-bottom: 24px;
    text-align:left;
  }
  &__item {
    position: relative;
    margin: 0 auto;
    border: none;
    max-width: 400px;

    &:first-of-type {
      width: calc(100% - 48px);
      top:0;
      margin-bottom: 24px;
    }
    &:last-of-type {
      bottom: 0;
      width: calc(100% - 48px);
      left: 0;
    }
  }
}

// マップ
#p-map {
  height: 300px;
}

.p-instagram {
  display:none;
}



.p-top-about__wave-top {
  margin-top: -30px;
  svg {
    display: block;
    width: calc(100% + 1.3px);
    height: 30px;
  }
}


.p-top-about__wave-bottom {
  bottom: -30px;
  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 30px;
  }
}
