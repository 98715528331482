// コース紹介
.p-course {
  max-width: 1200px;
  margin: 0 auto 96px;
  &__ttl {
    margin-bottom: 56px;
    text-align: center;
    &__main {
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 8px;
      letter-spacing: 0.1em;
      display: block;
    }
    &__sub {
      font-size: 1.4rem;
      color:rgba(0,0,0,0.25);
      font-family: 'Nunito', sans-serif;
      display: block;
      letter-spacing: 0.05rem;
    }
  }

  &__box {
    display: flex;
    padding: 48px;
    background:#fff;
    border-radius: 20px;
    flex-wrap: wrap;
    position:relative;
  }

  // &__btn {
  //   margin:24px 0 0 0;
  // }


  &-age {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &__ttl {
      background:#f9f5f3;
      border-radius: 6px;
      margin-right: 12px;
      padding: 6px 12px;
    }
    &__txt {
      span {
        color:#666;
        margin-left: 12px;
        font-size: 1.2rem;
      }
    }
  }


  &__img {
    width: 42%;
    max-width: 480px;
    margin-right: 48px;
    border-radius:20px;
    overflow:hidden;
    img {
      border-radius: 20px;
    }
  }
  &__content {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    &__ttl {
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 24px;
      letter-spacing: 0.1em;
    }
    &__txt {
      letter-spacing: 0.05em;
      line-height: 2;
      margin-bottom: 32px;
      span {
        font-size: 1.2rem;
        display: block;
      }
      &__link {
        color: $themeColor;
        margin-top:12px;
        &::before{
          left: 3px;
          width: 4px;
          height: 4px;
          border-top: 2px solid $themeColor;
          border-right: 2px solid $themeColor;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          display:inline-block;
          content: '';
          margin-right: 6px;
        }
      }

    }
  }

  &-info {
    // border:1px solid #aaa;
    width: 100%;
    margin-bottom: 48px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__box {
      display: flex;
      border-bottom: 1px solid #E3E3E3;
      background:#f9f5f3;
      &:last-of-type {
        border-bottom: none;
      }
    }
    &__ttl {
      background:#F2ECE9;
      // border-right: 1px solid #aaa;
      width: 30%;
      padding: 12px 16px;
    }
    &__txt {
      flex:1;
      padding: 12px 16px;
      span {
        font-size: 1.2rem;
        margin-left: 12px;
      }
    }
  }
}

.p-voice {
  width: 100%;
  padding: 48px 0 0;

  &__detail {
    margin: 0 24px 0 0;
  }

  &__btn {
    display: flex;
    margin: 0;
    width: 200px;
    height: 52px;
    line-height: 50px;
    border-radius: 30px;
    background:#fff;
    border: 2px solid $textColor;
    position:relative;
    color:$textColor;
    font-weight:$bold;
    font-size: 1.4rem;
    align-items: center;
    justify-content: center;
    // right:0;
    // bottom:-42px;

    &-wrap {
      position:relative;
      width: 100%;
      display:flex;
      justify-content: flex-end;
      margin: 24px 0 0;
    }


    &::before,
    &::after {
      content: '';
      display: block;
      width: 15px;
      height: 2px;
      border-radius: 5px;
      background: #000;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
    
    &::after {
      background: #000;
      transform: translateY(-50%) rotate(90deg);
      transition: 0.5s;
    }
    &.is-active::after {
      transform: rotate(0);
      transition: 0.5s;
    }
  }
  &__ttl {
    font-size: 2rem;
    margin-bottom: 24px;
    font-weight: 600;
  }
  &__list {
    counter-reset: my-counter;
  }
  &__item {
    margin-bottom: 24px;
    line-height:1.8;
    border: 2px solid #F2EAE6;
    padding: 24px 24px 24px 60px;
    border-radius: 20px;
    position:relative;
    
    &:before {
      content: counter(my-counter);
      counter-increment: my-counter;
      background-color: $textColor;
      border: 1px solid;
      border-radius: 50%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 28px;
      color: #ffffff;
      font-weight: 600;
      font-size: 100%;
      line-height: 1;
      position: absolute;
      top: 20px;
      left: 20px;    
    }
  }
}
