// _font.scss
@font-face {
  font-family: "NotoSansJP";
  font-style: normal;
  font-weight: 400;
  src: local("NotoSansJP-Medium"),
    url("/assets/fonts/NotoSansJP-Regular.woff2") format("font-woff2"),
    url("/assets/fonts/NotoSansJP-Regular.woff") format("font-woff"),
    url("/assets/fonts/NotoSansJP-Regular.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "NotoSansJP";
  font-style: normal;
  font-weight: 500;
  src: local("NotoSansJP-Medium"), url("/assets/fonts/NotoSansJP-Medium.woff2") format("font-woff2"),
    url("/assets/fonts/NotoSansJP-Medium.woff") format("font-woff"),
    url("/assets/fonts/NotoSansJP-Medium.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "NotoSansJP";
  font-style: normal;
  font-weight: 700;
  src: local("NotoSansJP-Bold"), url("/assets/fonts/NotoSansJP-Bold.woff2") format("font-woff2"),
    url("/assets/fonts/NotoSansJP-Bold.woff") format("font-woff"),
    url("/assets/fonts/NotoSansJP-Bold.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato-Regular"), url("/assets/fonts/Lato-Regular.woff2") format("font-woff2"),
    url("/assets/fonts/Lato-Regular.woff") format("font-woff"),
    url("/assets/fonts/Lato-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato-Bold"), url("/assets/fonts/Lato-Bold.woff2") format("font-woff2"),
    url("/assets/fonts/Lato-Bold.woff") format("font-woff"),
    url("/assets/fonts/Lato-Bold.ttf") format("truetype");
  font-display: swap;
}
@media print {
  html body * {
    font-family: "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
      "ＭＳ Ｐゴシック", sans-serif;
  }
}
