.c-modal {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;

  &__inner {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 48px 0;
    overflow: auto;
    white-space: nowrap;

    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 1px;
      height: 100%;
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.33);
    width: 100%;
    height: 100%;
  }

  &__contents {
    max-width: 680px;
    width: 80%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background: #ffffff;
    white-space: normal;
    border-radius: 6px;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #e5e5d5;
    padding: 1px 5px;

    img {
      display: block;
    }
  }

  &--basic {
    .simplebar-scrollbar:before {
      background: $basic-mainColor;
    }
  }
}
