.c-roundTab {
  display: flex;
  box-shadow: 0 0 0 1px #dbdad7 inset;
  width: 100%;
  border-radius: 27px;
  overflow: hidden;
  background: #fff;

  &__item {
    flex: 1;
    border-right: 1px solid #dbdad7;
    text-align: center;

    &:last-of-type {
      border-right: none;
    }

    &__btn {
      width: 100%;
      line-height: 1.4;
      padding: 10px 0;
      font-size: 1.4rem;
      font-weight: bold;

      &.is-active--basic {
        background: linear-gradient(to right, $basic-subColor, $basic-mainColor);
        color: #fff;
        cursor: default;
        height: 100%;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__content {
    display: none;

    &.is-active {
      display: block;
    }
  }
}

// ---------- baseTab ----------

.c-baseTab {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 77px;
  padding: 0 24px;
  z-index: 0;

  &-basic {
    &::before {
      background: linear-gradient(to right, $basic-subColor, $basic-mainColor);
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    z-index: -2;
  }

  &__item {
    position: relative;
    flex: 1;
    margin: 0 7px;
    overflow: hidden;
    border-radius: 5px 5px 0 0;

    &--active {
      height: 77px;
    }
  }

  &__btn {
    width: 100%;
    height: 62px;
    font-size: 1.6rem;
    font-family: $font_sans;
    font-weight: $bold;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    span {
      position: relative;
      text-align: center;
    }

    &.is-unread {
      span {
        &::after {
          display: block;
          width: 7px;
          height: 7px;
          background: #e6183f;
          border-radius: 7px;
          border: 1px solid #fff;
          position: absolute;
          content: "";
          right: -11px;
          top: -4px;
        }
      }
    }

    &--basic {
      background: linear-gradient(to right, $basic-subColor, $basic-mainColor);
      color: #fff;
      &.is-active::before {
        background: linear-gradient(to right, $basic-subColor, $basic-mainColor);
      }
    }

    &.is-active {
      position: relative;
      margin: 5px 5px 0 5px;
      width: calc(100% - 10px);
      min-height: 66px;
      background: #fff;
      border-radius: 3px 3px 0 0;
      box-sizing: border-box;
      cursor: default;
      color: #000;

      &::before {
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        bottom: 1px;
        left: 0;
        margin: -5px -5px 0 -5px;
        border-radius: 5px 5px 0 0;
        z-index: -1;
      }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   bottom: -5px;
      //   left: 0;
      //   width: 100%;
      //   // height: 4px;
      //   background: #fff;
      //   z-index: 1;
      // }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__content {
    display: none;

    &.is-active {
      display: block;
    }
  }
}
