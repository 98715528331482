// 入会について
.p-rythmique {
  max-width: 1300px;
  margin: 120px auto 106px;
  &__ttl {
    margin-bottom: 56px;
    text-align: center;
    &__main {
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 8px;
      letter-spacing: 0.1em;
      display: block;
    }
    &__sub {
      font-size: 1.4rem;
      color:rgba(0,0,0,0.25);
      font-family: 'Nunito', sans-serif;
      display: block;
      letter-spacing: 0.05rem;
    }
  }

  &-box {
    display: flex;
    // width: 1000px;
    margin: auto;
  }

  &__trial-ttl {
    width:530px;
    padding: 24px 32px;
    position: relative;
    text-align: center;
    font-size: 2.2rem;
    margin:32px auto 48px;
    letter-spacing: 0.1em;

    &::before,
    &::after {
      content: '';
      width: 25px;
      height: 25px;
      position: absolute;
    }
    &::before {
      border-left: solid 1px $textColor;
      border-top: solid 1px $textColor;
      top: 0;
      left: 0;
    }
    &::after {
      border-right: solid 1px $textColor;
      border-bottom: solid 1px $textColor;
      bottom: 0;
      right: 0;
    }
  }

  &__img {
    width: 42%;
    max-width: 480px;
    img {
      border-radius:20px;
    }
  }

  &__right {
    max-width: 720px;
    margin: auto;
    margin-left: 100px;

  }

  &__lead {
    font-size: 1.4rem;
    line-height: 2.4em;
    margin-bottom: 24px;
     a{
      display:inline-block;
      padding: 0 8px; 
      color:#849823
     }
  }

  &-about {
    background:#fff;
    height: auto;
    max-width: 1300px;
    margin: 0 auto 120px;
    display: flex;
    padding: 48px;
    background:#fff;
    border-radius: 20px;
    flex-wrap: wrap;
    position:relative;

    &__btn {
      width: auto;
      padding: 0 0;
      margin:0;
      width: 430px;
    }
    &__ttl {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 24px;
      letter-spacing: 0.1em;
    }

    &__img {
      width: 36%;
      max-width: 480px;
      margin-left: 48px;
      border-radius:20px;
      overflow:hidden;
      img {
        border-radius: 20px;
      }
    }
    &__content {
      flex: 1;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
  
      &__txt {
        letter-spacing: 0.05em;
        line-height: 2;
        margin-bottom: 32px;
        span {
          font-size: 1.2rem;
          display: block;
        }
  
      }
    }
    
  }

}
