.p-news-d {
  &__detail-wrap {
    display: flex;
    gap: 4%;
    max-width: 1200px;
    margin: 105px auto 0;
  }
  &__content {
    flex:1;
  }

  &__date {
    display: flex;
    align-items: baseline;
    margin-bottom: 18px;

    &-txt {
      margin-right: 15px;
      color: $themeColor;
      font-size: 1.4rem;
      font-weight: bold;
      font-family: 'Nunito', sans-serif;
    }

    &-label {
      // border: 1px solid #e8e8e8;
      border-radius: 3px;
      padding: 5px 20px;
      background-color: #e3dad5;
      font-size: 1.2rem;
      font-weight: $normal;
      border-radius: 24px;
      a:nth-of-type(n+2){
        display: none;
      }
    }
  }

  &-ttl {
    margin-bottom: 18px;
    font-size: 2.4rem;
    font-weight: bold;
    border-bottom: 1px solid $darkBaseColor;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }


  &__content-wrap {
    font-size: 1.4rem;
    line-height:1.8;
    margin-bottom: 80px;
    a {
      display: inline;
      color:$themeColor;
    }
    // p:nth-child(1) {
    //   margin-bottom: 40px;

      
    //   font-size: 1.6rem;
      
    //   line-height: 1.75;
    // }

    // p:nth-child(2) {
    //   margin-bottom: 24px;

      
    //   font-size: 16px;
    // }

    // p:nth-child(3) {
    //   margin-bottom: 24px;

      
    //   font-size: 16px;
      
    // }

    // a:nth-child(4) {
    //   margin-bottom: 65px;
    //   font-size: 16px;
    //   text-decoration: underline;
    //   // color: #5cbda5;
    // }

    ul {
      margin-bottom: 56px;
      li {
        margin-bottom: 24px;
        text-align: justify;

        
        font-size: 1.6rem;
      }

      li::before {
        display: inline-block;
        content: "";
        border-radius: 50%;
        background-color: #3761b1;

        min-width: 14px;
        min-height: 14px;
        margin-right: 15px;
      }
    }

    ol {
      margin-bottom: 56px;
      counter-reset: li;

      li {
        counter-increment: li;
        margin-bottom: 24px;
        text-align: justify;
        
        font-size: 1.6rem;
      }

      li::before {
        content: counter(li);
        margin-right: 15px;

        
        color: #3761b1;
        font-size: 1.8rem;
        font-weight: bolder;
      }
    }

    hr {
      border-top: 0.5px solid #b4c1db;
      margin-bottom: 105px;
    }

    blockquote {
      margin: 0;

      border: 1px solid #5cbda5;
      padding: 24px;
      margin: 0 0 96px 0;

      
      font-size: 16px;
      font-weight: $normal;
      line-height: 1.75;
      
    }

    blockquote::before {
      display: inline-block;
      content: "";
      width: 32px;
      height: 16px;
      flex: 0 1 32px;
      background-image: url(../img/common/ico-quote.svg);
      background-position: left;
      background-repeat: no-repeat;
      background-size: inherit;
    }
  }

  &__img {
    margin-bottom: 56px;
    width: 100%;
    height: auto;
  }

  &__back {
    display: flex;
    justify-content: center;
    margin-bottom: 112px;

    &-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid 1px #3761b1;
      border-radius: 6px;

      padding: 24px 56px 24px 40px;
      
      font-weight: bold;
      font-size: 1.6rem;
      text-decoration: none;
      color: #38343c;

      &::before {
        content: "";
        width: 32px;
        height: 32px;
        min-height: 32px;
        margin-right: 60px;
        flex: 0 1 32px;
        border-radius: 50%;
        background-image: url(../img/common/ico-arrow-white.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: inherit;
        background-color: #5cbda5;
        transform: rotate(3.142rad);
      }
    }
  }

}
