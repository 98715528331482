/* --------------------------------------------
  base
----------------------------------------------- */

html {
  font-size: 62.5%;
  height: -webkit-fill-available;

}
body {
  min-width: $breakpoint-pc;
  @include font(14, normal, $textColor, 0, $normal);
  font-family: $font_sans;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background:$baseColor;
  @media only screen and (max-width: $breakpoint) {
    min-width: auto;
  }
}

a,
button {
  display: block;
  color: $textColor;
  text-decoration: none;
  transition: all $duration-fast ease-out 0s;
  cursor: pointer;

  &:hover {
    opacity: $defOpacity;
    transition: all $duration-fast ease-out 0s;
  }
  @media only screen and (max-width: $breakpoint) {
    &:hover {
      opacity: 1;
    }
  }
}

input,
textarea,
select {
  font-family: $font_sans;
  &::placeholder {
    color: #b7b7b7;
  }
}

img,
video {
  width: 100%;
  vertical-align: bottom;

  &[src$=".svg"] {
    width: auto;
    min-width: 100%;
    max-width: 100%;
  }
}

.only-sp {
  display: none;
}
.only-pc {
  display: block;
}
@media only screen and (max-width: $breakpoint) {
  .only-sp {
    display: block;
  }
  .only-pc {
    display: none !important;
  }
}

.is-fixed {
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
}
// [data-scroll] {
//   transition: opacity 1s;
// }
// [data-scroll="in"] {
//   opacity: 1;
// }
// [data-scroll="out"] {
//   opacity: 0;
// }

.fade-bottom {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: all 1s;
  z-index: 3;
  &.is-show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}


.fade-top {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 1s;
  z-index: 3;
  &.is-show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}

.fade-in {
  opacity: 0;
  visibility: hidden;
  // transform: 0;
  transition: all 0.8s;
  z-index: 3;
  &.is-show {
    opacity: 1;
    visibility: visible;
    // transform: translateY(0px);
  }
}

