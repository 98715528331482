.l-footer {
  width: 100%;
  overflow: hidden;
  margin-top: auto;

  &__logo {
    width:50%;
    max-width: 300px;
    margin:0 auto 24px;
  }

  &__backToTop {
    width: 100%;
    height: 40px;
    margin: 0 0 64px 0;
    position: relative;
    background:#849823;

    @include beforeImage(10, 15, "../img/common/ico-arrow-white-up.svg");
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-nav {
    width: 100%;
    margin: 0 0 64px 0;

    &__list {
      display: flex;
      justify-content: center;
      width: 70%;
      margin: 0 auto;
    }

    &__item {
      margin: 0 32px 0 0;

      &:last-child {
        margin: 0;
      }

      a {
        font-weight: $normal;
      }

      &--link {
        a {
          display: flex;
          align-items: center;
          @include afterImage(15, 15, "../img/common/ico-link-black.svg");
          &::after {
            margin: 0 0 0 6px;
          }
        }
      }

      &--info {
        a {
          display: flex;
          align-items: center;
          @include beforeImage(15, 15, "../img/common/ico-info-black.svg");
          &::before {
            margin: 0 4px 0 0;
          }
        }
      }

      &--video {
        a {
          display: flex;
          align-items: center;
          @include beforeImage(15, 15, "../img/common/ico-video-black.svg");
          &::before {
            margin: 0 4px 0 0;
          }
        }
      }
    }
  }
  &__address {
    text-align:center;
    margin: 24px 0 32px;
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
  }

  &__copyright {
    max-width: 1000px;
    width: 100%;
    border-top: 1px solid #ddd;
    font-size: 1.2rem;
    font-weight: $normal;
    margin: 0 auto;
    padding: 32px 0 32px;
    text-align: center;
    letter-spacing: 0.1rem;
  }
}
