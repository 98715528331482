/* --------------------------------------------
  page-head
----------------------------------------------- */

.l-page-head {
  // width:100%;
  height: 330px;

  background:linear-gradient(rgba(0, 0, 0, .2),  rgba(0, 0, 0, .2)), url('../img/common/bac-page-head.jpg');
  background-size: cover;
  position:relative;
  width: calc(100% - 140px);
  margin: auto;
  border-radius: 20px;

  &__inner {
    width: 90%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__ttl {
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: .2em;
    text-align: left;
  }
  &__sub-ttl {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: .15em;
    font-family: "Nunito",sans-serif;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    margin-top: 16px;
    letter-spacing: 0.6em;
  }

  //ナビ
  &__nav {
    // position:absolute;
    z-index: 9;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: -30px auto 120px;
    min-height: 60px;
    background:#fff;
    border-radius: 20px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.06);
    padding: 16px 24px 16px;
    position:relative;
    z-index:1;
    max-width: 500px;

    &__item {
      margin: 8px 16px;
    }

    a {
      color:#849823;
    }
  }

  &__sub-txt {
    position:absolute;
    left: 5%;
    bottom: -120px;
  }
}