.l-content {
  margin-top: 68px;
  &__inner {
    display :block;
    width: calc(100% - 48px);
  }

  .l-main {
    &__inner {
      margin: 32px 0 80px;
      width: auto;
    }
  }
}
