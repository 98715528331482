// 5つの想い
.p-point {
  max-width: 1200px;
  margin: auto;
  &__ttl {
    margin-bottom: 56px;
    text-align: center;
    &__main {
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 8px;
      letter-spacing: 0.1em;
      display: block;
    }
    &__sub {
      font-size: 1.4rem;
      // font-weight: bold;
      color:rgba(0,0,0,0.25);
      font-family: 'Nunito', sans-serif;
      display: block;
      letter-spacing: 0.05rem;
    }
  }

  &__list {

  }

  &__item {
    display: flex;
    margin-bottom:100px;
    &:nth-of-type(odd){
      .p-point__img {
        order: 1;
      }
      .p-point__content {
        order: 2;
        margin-left :100px;
      }
    }
    &:nth-of-type(even){
      .p-point__img {
        order: 2;
      }
      .p-point__content {
        order: 1;
        margin-right :100px;
      }
    }

    &:nth-of-type(1){
      .p-point__content::before {
        width: 47px;
        background:url('../img/about/ico-01.svg');
      }
    }
    &:nth-of-type(2){
      .p-point__content::before {
        width: 53px;
        background:url('../img/about/ico-02.svg');
      }
    }
    &:nth-of-type(3){
      .p-point__content::before {
        width: 53px;
        background:url('../img/about/ico-03.svg');
      }
    }
    &:nth-of-type(4){
      .p-point__content::before {
        width: 55px;
        background:url('../img/about/ico-04.svg');
      }
    }
    &:nth-of-type(5){
      .p-point__content::before {
        width: 54px;
        background:url('../img/about/ico-05.svg');
      }
    }

  }
  &__img {
    width: 42%;
    max-width: 480px;
    border-radius:20px;
    overflow:hidden;
    img {
      border-radius:20px;
    }

  }
  &__content {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    &::before {
      display: block;
      // width: 46px;
      height: 38px;
      margin-bottom: 16px;
      background:url('../img/about/ico-01.svg');
      content :'';
      background-repeat:no-repeat !important;
      background-size:contain !important;
    }

    &__ttl {
      font-size: 2.4rem;
      font-weight: 600;
      margin-bottom: 24px;
      letter-spacing: 0.1em;
    }
    &__txt {
      letter-spacing: 0.05em;
      line-height: 2;
    }
  }
}

// 講師紹介
.p-coach {
  position: relative;
  margin: 240px auto 200px;
  // padding: 120px 0;
  // background:#fff;
  &__bac {
    width: 100%;
    left:0;
    right: 0;
    position: absolute;
    z-index:-2;

    img {
      height: 100%;
      width: 100%;
    }

    &--top {
      top: -48px;
      height: 50px;
      background:url("../img/top/bac-curve-top2.svg");
      background-repeat:no-repeat;
      background-size: cover;
    }
    &--bottom {
      bottom:-48px;
      height: 50px;
      background:url("../img/top/bac-curve-bottom2.svg");
      background-repeat:no-repeat;
      background-size: cover;
    }
  }
  &__box {
    position:relative;
    &__img {
      width:50%;
      max-width: 1000px;
      position: absolute;
      top:-120px;
      left:0;
      right:0;
      z-index:-1;
      opacity:0;
      margin: auto;
      transition: all 1.3s 0.6s ease-out;

      &.is-show {
        opacity:1;
        width:96%;
      }
    }
  }
  &__ttl {
    margin-bottom: 56px;
    text-align: center;
    &__main {
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 8px;
      letter-spacing: 0.1rem;
      display: block;
    }
    &__sub {
      font-size: 1.4rem;
      // font-weight: bold;
      color:rgba(0,0,0,0.25);
      font-family: 'Nunito', sans-serif;
      display: block;
      letter-spacing: 0.05rem;
    }
  }
  &__right {
    display:flex;
    flex-direction: column;
    justify-content: center;
  }

  &__content {
    display: flex;
    max-width: 1200px;
    margin: auto;
    width: 92%;
    background:#fff;
    padding: 48px;
    border-radius:20px;
  }
  &__img {
    width: 35%;
    margin-right: 56px;
    border-radius: 20px;
    overflow: hidden;
  }

  &__name {
    color:#849823;
    font-weight: 600;
    font-size: 2.4rem;
    margin-bottom: 32px;
    letter-spacing:0.15em;
  }
  &__list {
    margin-bottom: 20px;
    letter-spacing: 0.1em;
  }
  &__item {
    margin-bottom: 8px;
  }
}

// アクセス
.p-access {
  width: calc(100% - 48px);
  max-width: 720px;
  border-radius: 20px;
  margin: auto;
  background: #fff;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.03);
  display: flex;
  padding: 24px 48px;
  margin-bottom: -44px;
  position:relative;
  z-index:1;
  &__head {
    text-align:center;
    border-right: 1px solid #E5DDD3;
    padding-right: 32px;
    margin-right: 32px;
    display:flex;
    align-items: center;
  }
  &__main-ttl {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 4px;
    letter-spacing: 0.1em;
    display: block;
  }
  &__sub-ttl {
    font-size: 1.2rem;
    color:rgba(0,0,0,0.25);
    font-family: 'Nunito', sans-serif;
    display: block;
    letter-spacing: 0.05rem;
  }

  &__box{
    display: flex;
    align-items: center;
    p {
      margin-bottom: 4px;
      letter-spacing:0.05em;
    }
  }
  &__link {
    background:#849823;
    margin-top:6px;
    border-radius: 24px;
    padding: 8px 14px 8px 28px;
    line-height:1;
    color:#fff;
    letter-spacing: 0.05em;
    display: inline-block;
    position:relative;
    &::before {
      background:url('../img/common/ico-pin.svg');
      width: 9px;
      height: 14px;
      content: '';
      display:block;
      position:absolute;
      left:14px;
      top:0;
      bottom:0;
      margin:auto;
    }    
  }
}