@charset "UTF-8";

// reset
@import "core/_reset";

// core
@import "core/_font";
@import "core/_variable";
@import "core/_mixin";
@import "core/_keyframes";
@import "core/_base";

// libs
@import "libs/jquery-ui.min.scss";
@import "libs/swiper-bundle.scss";


// component pc
@import "component/button/_btn_pc.scss";
@import "component/modal/_modal_pc.scss";
@import "component/tab/_tab_pc.scss";
@import "component/title/_title_pc.scss";
// layout pc
@import "layout/content/_layout_pc.scss";
@import "layout/footer/_footer_pc.scss";
@import "layout/header/_header_pc.scss";
@import "layout/page-head/_page-head_pc.scss";
// page pc
@import "project/about/_about_pc.scss";
@import "project/course/_couse_pc.scss";
@import "project/entrance/_entrance_pc.scss";
@import "project/lesson/_calender_pc.scss";
@import "project/lesson/_lesson_pc.scss";
@import "project/news/_category-news_pc.scss";
@import "project/news/_detail_pc.scss";
@import "project/news/_list_pc.scss";
@import "project/rytmique/_rytmique_pc.scss";
@import "project/top/_top_animation_pc.scss";
@import "project/top/_top_pc.scss";

@media only screen and (max-width: $breakpoint) {
  // component sp
  
@import "component/modal/_modal_sp.scss";
@import "component/tab/_tab_sp.scss";
@import "component/title/_title_sp.scss";
  // layout sp
  
@import "layout/content/_layout_sp.scss";
@import "layout/footer/_footer_sp.scss";
@import "layout/header/_header_sp.scss";
@import "layout/page-head/_page-head_sp.scss";
  // page sp
  
@import "project/about/_about_sp.scss";
@import "project/course/_couse_sp.scss";
@import "project/entrance/_entrance_sp.scss";
@import "project/lesson/_lesson_sp.scss";
@import "project/news/_category-news_sp.scss";
@import "project/news/_detail_sp.scss";
@import "project/news/_list_sp.scss";
@import "project/rytmique/_rytmique_sp.scss";
@import "project/top/_top_sp.scss";
}
