.p-news-cat {
  width: 24%;
  &__ttl {
    background-color: $darkBaseColor;
    padding: 10px 24px;
    color: $textColor;
    font-size: 1.6rem;
    font-weight: $bold;
    border-radius: 24px;
  }

  &__list {
    margin-bottom: 54px;

    &-item {
      border-bottom: 1px solid $darkBaseColor;

      a {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 16px 24px;

        color: #38343c;
        
        font-size: 1.4rem;
        

        &::after {
          display: block;
          content: "";

          flex: 0 1 10px;
          background: url(../img/common/ico-arrow-blue.svg);
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  &__yearly {
    margin-bottom: 54px;
  }

  &__minus-txt,
  &__plus-txt {
    display: none;
  }
}
